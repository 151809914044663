
        <span class="numberLabel">{{ dayComposite.dayOfMonth }}</span>
        <div class="shift-overview">
            <table class="shift-overview__table">
                <tbody class="shift-overview__shift" *ngFor="let shift of dayComposite.dailyShiftConfig?.shifts | sortShiftByStartTimePipe" [ngClass]="shift.fulfilment.type">
                    <tr>
                        <td [style.width.%]="80" class="shift-type" [tooltip]="shift.fulfilment.shiftId.type | translate" [containerClass]="'pretty-tooltip'">{{ shift.fulfilment.shiftId.type.slice(0, 11) | translate }} ({{ shift.statuses['RESERVED'] | number }}/{{ shift.statuses['ORDERED'] | number }})</td>

                        <td class="shift-overview__fleet" [style.width.%]="20" [style.text-align]="'right'" colspan="1">
                            {{ shift.fulfilment.fleetMaximum }}
                        </td>
                    </tr>

                    <tr>
                        <td class="bars">
                            <div>
                                <div *ngIf="!(shift.shiftStatusData.status | isShiftOpenPipe)" class="bars__shift-status"><i class="fas fa-lock"></i></div>
                            </div>

                            <div class="bars__bar" [ngClass]="{'limit-alert': shift | checkShiftLimitsPipe }" [tooltip]="tooltipLabel(shift)" [adaptivePosition]="true" [containerClass]="'pretty-tooltip'" [style.width.%]="!(shift.shiftStatusData.status | isShiftOpenPipe) ? 82 : 95">
                                <div
                                    [style.flex]="shift.statuses['RESERVED']"
                                    class="bars__deliveries bars__deliveries--reserved"
                                    [style.background-color]="isLimitExceeded(shift) ? '#FF4136' : isEstimatedMaxNoDeliveriesExceeded(shift) && '#FFDC00'"
                                ></div>
                                <div
                                    [style.flex]="shift.statuses['ORDERED']"
                                    class="bars__deliveries bars__deliveries--ordered"
                                    [style.background-color]="isLimitExceeded(shift) ? '#FF4136' : isEstimatedMaxNoDeliveriesExceeded(shift) && '#FFDC00'"
                                ></div>
                                <div [style.flex]="shift.statuses['DONE']" class="bars__deliveries bars__deliveries--done"></div>
                                <div [style.flex]="shift.statuses['FAILURE']" class="bars__deliveries bars__deliveries--failure"></div>
                                <div [style.flex]="shift.statuses['ASSIGNED']" class="bars__deliveries bars__deliveries--assigned"></div>
                                <div [style.flex]="shift.statuses['RETRY']" class="bars__deliveries bars__deliveries--retry"></div>
                                <div [style.flex]="shift.statuses['PENDING']" class="bars__deliveries bars__deliveries--pending"></div>
                                <div [style.flex]="shift.statuses['ARCHIVED']" class="bars__deliveries bars__deliveries--archived"></div>
                                <div [style.flex]="shift.statuses['NEW']" class="bars__deliveries bars__deliveries--new"></div>
                                <div [style.flex]="shift.statuses['CUTOFF']" class="bars__deliveries bars__deliveries--cutoff"></div>
                                <div *ngIf="shift.shiftStatusData.status | isShiftOpenPipe" [style.flex]="flexGrowEstimatedMaxNoDeliveries(shift)" class="bars__deliveries bars__deliveries--maximum-deliveries"></div>
                            </div>
                        </td>
                        <td [style.text-align]="'right'">
                            <span *ngIf="!fullShift(shift)" class="shift-overview__maximum-deliveries" [tooltip]="allDeliveriesTooltip(shift)" [containerClass]="'pretty-tooltip'">
                                {{ estimatedMaxNoDeliveries(shift.fulfilment.fleetMaximum, shift.fulfilment.shiftId.type) }}
                            </span>

                            <span *ngIf="fullShift(shift)" [tooltip]="limitLabel()" class="shift-overview__maximum-deliveries" [containerClass]="'pretty-tooltip'" [style.color]="'red'">
                                {{ estimatedMaxNoDeliveries(shift.fulfilment.fleetMaximum, shift.fulfilment.shiftId.type) }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    