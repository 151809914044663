import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import * as _ from 'lodash';
import { Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DeliveriesSearchResponse } from '@entities/deliveries-search-response';
import { Delivery } from '@entities/delivery';
import { Item } from '@entities/item';
import { PageableResponse } from '@entities/pagable-response';
import { environment } from '@environment';
import { UtilsService } from '@services/utils.service';

import { AddDeliveryObject } from '../entities/add-delivery';
import { DeliveryFileType } from '@interfaces/files.interface';
import { UrlUtil } from '@shared/functions/UrlUtil';
import { SearchDeliveriesQueryParam } from '@deliveries/interfaces/search-deliveries-query.interdace';

export interface searchParams {
    value: string;
    type: string;
}

export enum searchType {
    CUSTOMER_REF = 'CUSTOMER_REF',
    DELIVERY_ID = 'DELIVERY_ID',
}

@Injectable()
export class DeliveriesService {
    public static SEARCH_CUSTOMER: string = 'customer/v1/search';
    public static ADD: string = 'delivery/v1/create';
    public static UPDATE: string = 'delivery/v1/${id}';
    public static LIST: string = 'delivery/v1/list/${status}?size=${size}&sort=${sort}&order=${order}&page=${page}';
    public static MOVE_TO_STATUS: string = 'delivery/v1/${deliveryId}/status';
    public static MOVE_TO_ARCHIVED: string = 'delivery/v1/${deliveryId}/archive';
    public static RESCHEDULE_DELIVERY: string = 'shifts/v1/${year}/${month}/${day}/${shift}/routes/${route}/delivery/${deliveryId}/reschedule';
    public static MOVE_DELIVERY_TO_ARCHIVED_AND_UNASSIGNED: string = 'shifts/v1/${year}/${month}/${day}/${shift}/routes/${route}/delivery/${deliveryId}/archive';
    public static GET_DELIVERY: string = 'delivery/v1/${deliveryId}';
    public static ADD_BOX_TO_DELIVERY: string = 'delivery/v1/${deliveryId}/box';
    public static DELIVERY_SEARCH: string = 'delivery/v1/search';
    public static DELIVERY_SEARCHBY_DRIVERID: string = 'driver/v1/deliveries/${driverId}?startDate=${startDate}&endDate=${endDate}';
    public static PAYMENT_DETAILS: string = 'delivery/v1/${deliveryId}/paymentdetails/${paymentTransactionId}';

    public static FETCH_SIGNATURE: string = 'delivery/v1/${id}/file/${deliveryFileType}';
    public static OLD_FETCH_SIGNATURE: string = 'delivery/v1/${id}/signature';

    private TAG = '[DeliveriesService]';

    private readonly host = environment.api.url;
    private readonly prefix = environment.api.prefix;

    private deliveriesSource: Subject<PageableResponse<Delivery>> = new Subject<PageableResponse<Delivery>>();
    public deliveries: Observable<PageableResponse<Delivery>> = this.deliveriesSource.asObservable();

    constructor(private http: HttpClient, private translate: TranslateService, private notifierService: NotifierService, private utilsService: UtilsService) {}

    public search(customerRef: string, type: string): Observable<any> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${DeliveriesService.SEARCH_CUSTOMER}`, {});
        const body: searchParams = { value: customerRef, type: type };

        return this.http.post(endpoint, body, this.utilsService.httpHeaders()).pipe(
            catchError((error: HttpErrorResponse) => {
                switch (error.status) {
                    case 404:
                        this.notifierService.notify('error', this.translate.instant('No results found! Please, try again'));
                        break;
                }
                return observableThrowError(error);
            }),
            map((data: any) => {
                return new DeliveriesSearchResponse().deserialize(data);
            })
        );
    }

    public create(requestBody: AddDeliveryObject): Observable<Delivery> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${DeliveriesService.ADD}`, {});
        delete requestBody.version;

        return this.http.post(endpoint, requestBody, this.utilsService.httpHeaders()).pipe(
            catchError((error) => {
                this.notifierService.notify('error', this.translate.instant('Error occurred, please try again!'));
                return observableThrowError(error);
            }),
            map((data: Delivery) => {
                const delivery = new Delivery().deserialize(data);
                return delivery;
            })
        );
    }

    public update(requestBody: AddDeliveryObject): Observable<any> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${DeliveriesService.UPDATE}`, { id: requestBody.deliveryId });
        return this.http.put(endpoint, requestBody, this.utilsService.httpHeaders()).pipe(
            catchError((error) => {
                this.notifierService.notify('error', this.translate.instant('Error occurred, please try again!'));
                return observableThrowError(error);
            }),
            map((data: Delivery) => {
                return new Delivery().deserialize(data);
            })
        );
    }

    /**
     * Get deliveries by status from backend
     * @param status
     * @param page
     * @param size
     * @param sort
     * @param order
     *
     * @return Observable<PageableResponse<Location>> The Paged Result of deliveries
     */
    public getDeliveriesByStatus(status: string, page: number, size: number, sort: string, order: string): Observable<PageableResponse<Delivery>> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${DeliveriesService.LIST}`, {
            status,
            page,
            size,
            sort,
            order,
        });

        return this.http.get(endpoint).pipe(
            map((response: any) => {
                const pageableResponse = new PageableResponse<Delivery>(Delivery);

                pageableResponse.contentItem = new Delivery();
                pageableResponse.deserialize(response);

                console.log(`${this.TAG} getDeliveriesByStatus`, pageableResponse);

                return pageableResponse;
            })
        );
    }

    /**
     * Perform search for route and delivery matching customer name or client id or delivery Id.
     *
     * @param year The year
     * @param month The month
     * @param day The day
     * @param phrase Searched phrase
     * @return Observable<PageableResponse<Delivery>>
     */
    public searchDelivery(queryparams: SearchDeliveriesQueryParam): Observable<any> {
        let endpoint = `${this.host}${this.prefix}/${DeliveriesService.DELIVERY_SEARCH}`;
        const paramsString = UrlUtil.objectToString(queryparams);

        if (paramsString) {
            endpoint += '?' + paramsString;
        }

        return this.http.get(endpoint, this.utilsService.httpHeaders()).pipe(
            catchError((error) => {
                this.notifierService.notify('error', this.translate.instant('Error occurred, please try again!'));
                return observableThrowError(error);
            }),
            map((searchResults: PageableResponse<Delivery>) => {
                const deliveriesRaw = _.cloneDeep(searchResults.content);
                searchResults.content = [];
                deliveriesRaw.forEach((d) => searchResults.content.push(new Delivery().deserialize(d)));
                this.deliveriesSource.next(searchResults);
                return searchResults.content;
            })
        );
    }

    /**
     * Perform search to find deliveries delivered by driver to be able to trace drivers contacts with customers
     *
     * @param driverId The driver Id
     * @param fromDate The from date
     * @param toDate The end date
     * @return Observable<PageableResponse<Delivery>>
     */
    public searchDeliveryByDriverIdAndDate(driverId?: string, startDate?: string, endDate?: string): Observable<any> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${DeliveriesService.DELIVERY_SEARCHBY_DRIVERID}`, { startDate, endDate, driverId });

        return this.http.get(endpoint, this.utilsService.httpHeaders()).pipe(
            catchError((error) => {
                this.notifierService.notify('error', this.translate.instant('Error occurred, please try again!'));
                return observableThrowError(error);
            })
        );
    }

    public moveDeliveryToArchive(deliveryId: string): Observable<Delivery> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${DeliveriesService.MOVE_TO_ARCHIVED}`, { deliveryId: deliveryId });

        return this.http.post(endpoint, null, this.utilsService.httpHeaders()).pipe(
            map((deliveryData: Delivery) => {
                const delivery = new Delivery().deserialize(deliveryData);
                this.notifierService.notify('success', this.translate.instant('Data has been updated'));
                return delivery;
            })
        );
    }

    public fetchSignature(id: string, deliveryFileType: DeliveryFileType): Observable<Blob> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${DeliveriesService.FETCH_SIGNATURE}`, { id, deliveryFileType });

        const HTTPOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }),
            responseType: 'blob' as 'json',
        };

        return this.http.get<any>(endpoint, HTTPOptions).pipe(map((signature: any) => signature));
    }

    public oldFetchSignature(id: string): Observable<Blob> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${DeliveriesService.OLD_FETCH_SIGNATURE}`, { id: id });

        const HTTPOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }),
            responseType: 'blob' as 'json',
        };

        return this.http.get<any>(endpoint, HTTPOptions).pipe(map((signature: any) => signature));
    }

    public getDelivery(deliveryId: string): Observable<Delivery> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${DeliveriesService.GET_DELIVERY}`, { deliveryId: deliveryId });

        return this.http.get(endpoint, this.utilsService.httpHeaders()).pipe(
            map((deliveryData: any) => {
                const delivery = new Delivery().deserialize(deliveryData);
                return delivery;
            })
        );
    }

    public fetchPaymentDetails(paymentTransactionId: string, deliveryId: string): Observable<any> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${DeliveriesService.PAYMENT_DETAILS}`, { deliveryId, paymentTransactionId });

        return this.http.post(endpoint, null, this.utilsService.httpHeaders());
    }

    public removeNonActivatedProducts(rowItems) {
        return _.forEach(rowItems, (item: Item) => item.isActivated && item.ordered > 0);
    }

    public removeNonOrderedProducts(rowItems) {
        return _.forEach(rowItems, (item: Item) => item.ordered > 0);
    }

    private interpolate(template: string, params: {}) {
        const names = Object.keys(params);
        const vals = Object.values(params);
        return new Function(...names, `return \`${template}\`;`)(...vals);
    }
}
